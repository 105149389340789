import React, { useMemo, useState } from 'react'
import cn from 'classnames'
import { getDaysInMonth } from 'date-fns'

import { Button } from '../../../components/Button'
import { Card } from '../../../components/Card'
import { Dropdown } from '../../../components/Dropdown'
import { FocusSection } from '../../../components/FocusSection'
import { TextGroup } from '../../../components/TextGroup'
import { TextWithImage } from '../../../components/TextWithImage'

import { CopyAndPaste } from '..'

import { calendarCircled, calendarMonospace, calendarMonospaceTwo, calendarNormal, calendarNormalTwo } from './functions'

import { tags } from './constants'

import MailIcon from './assets/email.svg'
import SocialIcon from './assets/social-media.svg'
import calendarImg from './assets/calendar.png'

import styles from './styles.module.scss'

type Variant = {
  fn: (timestamp: number) => string[]
}

const variants: Variant[] = [
  {
    fn: calendarMonospace
  },
  {
    fn: calendarMonospaceTwo
  },
  {
    fn: calendarNormal
  },
  {
    fn: calendarCircled
  },
  {
    fn: calendarNormalTwo
  }
]

const monthOptions = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

const CalendarGenerator: React.FC = () => {
  const [day, setDay] = useState(new Date().getDate().toString())
  const [month, setMonth] = useState(monthOptions[new Date().getMonth()])
  const [year, setYear] = useState(new Date().getFullYear().toString())

  const currentYear = new Date().getFullYear()
  const yearOptions = (new Array(100)).fill(1).map((_, i) => (currentYear + i).toString()).map(i => ({ label: i, value: i }))

  const timestamp = useMemo(() => {
    return new Date(parseInt(year), Object.values(monthOptions).findIndex(monthName => month === monthName), parseInt(day)).getTime()
  }, [year, month, day])

  const dayOptions = (new Array(getDaysInMonth(new Date(timestamp * 1000)))).fill(1).map((_, i) => (i + 1).toString())

  const reset = (): void => {
    setYear(new Date().getFullYear().toString())
    setMonth(monthOptions[new Date().getMonth()])
    setDay(new Date().getDate().toString())
  }

  return (
    <CopyAndPaste tags={tags} subtitleClassName={styles.subtitle} titleClassName={styles.title}>
      <div className={styles.row}>
        <div className={styles['inner-row']}>
          <Dropdown options={dayOptions} value={day} onChange={setDay} name='Day' small />
          <Dropdown options={monthOptions} value={month} onChange={setMonth} name='Month' small />
          <Dropdown input options={yearOptions} value={{ value: year, label: year }} onChange={setYear} name='Year' small />
        </div>
        <Button onClick={reset} secondary>Reset</Button>
      </div>
      <div className={styles['variants-container']}>
        {variants.map((variant, index) => (
          <Card
            className={styles.card}
            key={index}
            text={variant.fn(timestamp).join('\n')}
            textAlignCenter
            textFontSize={20}
            textClassName={cn(styles.mono, styles['calendar-text'])}
          />
        ))}
      </div>
      <div className={styles['text-container']}>
        <TextGroup title='What can I use the calendar generator for?'>
          You can use this tool to insert a calendar in multiple places online.
          Check out the following examples for ideas on how to use the calendar generator.
        </TextGroup>
        <TextGroup
          subtitle='Promote events on social media'
        >
          Perhaps you’re hosting a special event or have a birthday coming up soon.
          These calendars offer an unusual and eye-catching way to draw attention to an important date or event.
        </TextGroup>
      </div>
      <TextWithImage
        subtitle='Display links for multiple dates'
        image={
          <div className={styles['calendar-text-example']}>
            <div>
              <p>Roll up roll up.</p>

              <p>Last chance to see the 𝕬𝖒𝖆𝖟𝖎𝖓𝖌 𝕭𝖔𝖟𝖔.</p>
            </div>
            <img src={calendarImg} />
          </div>
        }
        style={{ marginTop: 64 }}
      >
        <>
          <p>
            You may want to post multiple links for an event, allowing customers to skip a step by choosing a date directly from social media or email:
          </p>
          <ol>
            <li>Copy a desired calendar to clipboard.</li>
            <li>Insert the calendar into a text editor or a messenger text field.</li>
            <li>Change calendar formatting for the needed dates (make them bold or underlined for example) and add hyperlink to the event.</li>
            <li>Post the created calendar to your social media or send it via email.</li>
          </ol>
          <p><b>Example:</b> The circus is in town for three nights only.</p>
        </>
      </TextWithImage>
      <FocusSection
        title='Where can you use these calendars?'
        textWithIcon={[
          {
            title: 'Email',
            text: 'The vast majority of email clients will render these correctly. For the best results, select the typeface Courier New if you’re using one of the monospace calendars.',
            icon: <MailIcon />
          },
          {
            title: 'Social media',
            text: <>
              <p>The last three calendar options will render fine almost anywhere online.</p>
              <p>
                However, social media sites will sometimes render the enclosed alphanumeric characters as emojis.
                Additionally, the monospace calendars won’t render properly on social media sites, except in code blocks on sites like Reddit.
              </p>
            </>,
            icon: <SocialIcon />
          }
        ]}
      />
    </CopyAndPaste>
  )
}

export default CalendarGenerator
