export const blankDate = {
  0: '░',
  1: '░',
  2: '░',
  3: '░',
  4: '░',
  5: '░',
  6: '░',
  7: '░',
  8: '░',
  9: '░'
}

export const monoDate: Record<string, string> = {
  0: '00',
  1: '01',
  2: '02',
  3: '03',
  4: '04',
  5: '05',
  6: '06',
  7: '07',
  8: '08',
  9: '09'
}

export const circledDates: Record<string, string> = {
  '01': '①',
  '02': '②',
  '03': '③',
  '04': '④',
  '05': '⑤',
  '06': '⑥',
  '07': '⑦',
  '08': '⑧',
  '09': '⑨',
  10: '⑩',
  11: '⑪',
  12: '⑫',
  13: '⑬',
  14: '⑭',
  15: '⑮',
  16: '⑯',
  17: '⑰',
  18: '⑱',
  19: '⑲',
  20: '⑳',
  21: '㉑',
  22: '㉒',
  23: '㉓',
  24: '㉔',
  25: '㉕',
  26: '㉖',
  27: '㉗',
  28: '㉘',
  29: '㉙',
  30: '㉚',
  31: '㉛'
}

export const calendarLines = {
  top: {
    tl: '╔',
    t: '═',
    tr: '╗',
    l: '║',
    r: '║'
  },

  first: {
    ttl: '╟',

    tl: '╟',
    t: '──',
    tr: '',
    r: '',
    br: '',
    b: '══',
    bl: '╚',
    l: '║'
  },
  mid: {
    ttl: '┬',

    tl: '┼',
    t: '──',
    tr: '',
    r: '',
    br: '',
    b: '══',
    bl: '╧',
    l: '│'
  },
  last: {
    ttl: '┬',

    tl: '┼',
    t: '──',
    tr: '╢',
    r: '║',
    br: '╝',
    b: '══',
    bl: '╧',
    l: '│'
  }
}

export const monoLines = {
  top: {
    tl: '┌',
    t: '─',
    tr: '┐',
    l: '│',
    r: '│'
  },

  first: {
    ttl: '├',
    tt: '──',
    ttr: '',

    tl: '├',
    t: '──',
    tr: '',
    r: '',
    br: '',
    b: '──',
    bl: '└',
    l: '│'
  },
  mid: {
    tt: '──',
    ttr: '',

    ttl: '┼',

    tl: '┼',
    t: '──',
    tr: '',
    r: '',
    br: '',
    b: '──',
    bl: '┴',
    l: '│'
  },
  last: {
    tt: '──',
    ttr: '┤',

    ttl: '┼',

    tl: '┼',
    t: '──',
    tr: '┤',
    r: '│',
    br: '┘',
    b: '──',
    bl: '┴',
    l: '│'
  }
}

export const normalLines = {
  top: {
    tl: '+',
    t: '—',
    tr: '+',
    l: '|',
    r: '|'
  },

  first: {
    ttl: '|',

    tl: '|',
    t: '—',
    tr: '',
    r: '',
    br: '',
    b: '',
    bl: '',
    l: ''
  },
  mid: {
    ttl: '|',

    tl: '|',
    t: '—',
    tr: '',
    r: '',
    br: '',
    b: '',
    bl: '',
    l: '|'
  },
  last: {
    ttl: '|',

    tl: '|',
    t: '—',
    tr: '|',
    r: '',
    br: '',
    b: '',
    bl: '',
    l: '|'
  }
}

export const circleLines = {
  top: {
    tl: '+',
    t: '—',
    tr: '+',
    l: ' ',
    r: '|'
  },

  first: {
    ttl: '·',

    tl: '·',
    t: '—',
    tr: '',
    r: '',
    br: '',
    b: '',
    bl: '',
    l: ' '
  },
  mid: {
    ttl: '·',

    tl: '·',
    t: '—',
    tr: '',
    r: '',
    br: '',
    b: '',
    bl: '',
    l: ' '
  },
  last: {
    ttl: '·',

    tl: '·',
    t: '—',
    tr: '·',
    r: ' ',
    br: '',
    b: '',
    bl: '',
    l: ' '
  }
}

export const tags = [
  { name: 'Unicode' },
  { name: 'Tools' },
  { name: 'Date' },
  { name: 'Time' },
  { name: 'Calendar' },
  { name: 'Copy and paste' }
]
