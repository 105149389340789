import { startOfMonth, startOfWeek, endOfMonth, endOfWeek, subDays, startOfDay, addDays, addWeeks, getMonth, getDate, format } from 'date-fns'

import { sansBoldDict, monospaceDict, squaredBlackDict, sansDict } from '../../FontGenerator/constants'
import { normalLines, monoDate, calendarLines, monoLines, circleLines, circledDates } from './constants'

type Item = {
  day: Date
  class: string
}

const blockcalendar = (timestamp: number): Item[][] => {
  const startDay = startOfMonth(startOfWeek(timestamp))
  const endDay = endOfMonth(endOfWeek(timestamp))

  let startDateMinusOne = subDays(startDay, 1)

  const calendar = []
  while (startOfDay(startDateMinusOne) < startOfDay(endDay)) {
    calendar.push(
      Array(7)
        .fill(0)
        .map((_, i) => ({
          day: addDays(startDateMinusOne, i),
          class: ''
        }))
    )
    startDateMinusOne = addWeeks(startDateMinusOne, 1)
  }

  calendar.forEach((week) => {
    week.forEach((day) => {
      if (getDate(day.day) === getDate(timestamp)) {
        day.class = 'today'
      } else if (getMonth(day.day) === getMonth(timestamp)) {
        day.class = 'month'
      } else {
        day.class = 'notmonth'
      }

      const dayNumber = getDate(day.day)

      day.day = dayNumber as any

      if (monoDate[(day.day as any)]) {
        day.day = monoDate[day.day as any] as any
      }
    })
  })

  return calendar
}

const calendarNorm = (timestamp: number): string[][][] => {
  const cl = normalLines

  const strArr: string[][][] = []

  blockcalendar(timestamp).forEach((week, y) => {
    const upperLine = []
    const middleLine: any[] = []
    const lowerLine = []

    week.forEach((day, x) => {
      let dayInner = day.day
      if (day.class === 'notmonth') { dayInner = '𝚡𝚡' as any }

      let dictpos = cl.mid
      if (x === 0) {
        dictpos = cl.first
      }
      if (x === 6) {
        dictpos = cl.last
      }

      // for each day, check it's x and y coordinates and create walls based on those coordinates
      if (y === 0) {
        upperLine.push([dictpos.ttl, dictpos.t, dictpos.tr])
        middleLine.push([dictpos.l, dayInner, dictpos.r])
      } else if (y < blockcalendar.length - 1) {
        upperLine.push([dictpos.tl, dictpos.t, dictpos.tr])
        middleLine.push([dictpos.l, dayInner, dictpos.r])
      } else {
        upperLine.push([dictpos.tl, dictpos.t, dictpos.tr])
        middleLine.push([dictpos.l, dayInner, dictpos.r])
        lowerLine.push([dictpos.bl, dictpos.b, dictpos.br])
      }
    })

    if (y < blockcalendar(timestamp).length - 1) {
      strArr.push(middleLine)
    } else {
      strArr.push(middleLine)
    }
  })

  // Add Today
  blockcalendar(timestamp).forEach((week, y) => {
    week.forEach((day, x) => {
      if (day.class === 'today') {
        strArr[y][x][1] = sansBoldDict[strArr[y][x][1]] || strArr[y][x][1]
      }
    })
  })

  return strArr
}

export const calendarNormal = (timestamp: number): string[] => {
  // Build top of Calendar
  const thisMonth = format(timestamp, 'MMMM')
  const thisYear = format(timestamp, 'y')
  const monthYear = thisMonth + ' – ' + thisYear

  const monoMonth = monthYear
    .toUpperCase()
    .split('')
    .map(e => monospaceDict[e] || e)
    .join('')

  // Create array of Strings
  const newArr = [
    monoMonth,
    '𝚂𝚞|𝙼𝚘|𝚃𝚞|𝚆𝚎|𝚃𝚑|𝙵𝚛|𝚂𝚊',
    '-------------------------------'
  ]

  calendarNorm(timestamp).forEach((arr) => {
    const anotherArr: string[] = []
    arr.forEach((innerArr: any) => {
      anotherArr.push(innerArr.join(''))
    })

    newArr.push(anotherArr.join(''))
  })

  const monoArr: string[] = []
  newArr.forEach((string) => {
    monoArr.push(
      string
        .split('')
        .map(e => monospaceDict[e] || e)
        .join('')
    )
  })

  return monoArr
}

const calendarMono = (timestamp: number): string[][][] => {
  const strArr: any[] = []

  const blockcal = blockcalendar(timestamp)

  blockcal.forEach((week, y) => {
    const upperLine: any[] = []
    const middleLine: any[] = []
    const lowerLine: any[] = []

    week.forEach((day, x) => {
      let dayInner = day.day
      if (day.class === 'notmonth') { dayInner = '░░' as any }

      let dictpos = calendarLines.mid
      if (x === 0) {
        dictpos = calendarLines.first
      }
      if (x === 6) {
        dictpos = calendarLines.last
      }

      // for each day, check it's x and y coordinates and create walls based on those coordinates
      if (y === 0) {
        upperLine.push([dictpos.ttl, dictpos.t, dictpos.tr])
        middleLine.push([dictpos.l, dayInner, dictpos.r])
      } else if (y < blockcal.length - 1) {
        upperLine.push([dictpos.tl, dictpos.t, dictpos.tr])
        middleLine.push([dictpos.l, dayInner, dictpos.r])
      } else {
        upperLine.push([dictpos.tl, dictpos.t, dictpos.tr])
        middleLine.push([dictpos.l, dayInner, dictpos.r])
        lowerLine.push([dictpos.bl, dictpos.b, dictpos.br])
      }
    })

    if (y < blockcal.length - 1) {
      strArr.push(upperLine, middleLine)
    } else {
      strArr.push(upperLine, middleLine, lowerLine)
    }
  })

  // Add Today
  blockcalendar(timestamp).forEach((week, y) => {
    week.forEach((day, x) => {
      if (day.class === 'today') {
        strArr[y * 2][x][0] = '╔'
        strArr[y * 2][x][1] = '══'
        strArr[y * 2 + 2][x][1] = '══'
        strArr[y * 2 + 2][x][0] = '╚'
        strArr[y * 2 + 1][x][0] = '║'

        if (x === 6) {
          strArr[y * 2][x][2] = '╗'
          strArr[y * 2 + 1][x][2] = '║'
          strArr[y * 2 + 2][x][2] = '╝'
        } else {
          strArr[y * 2][x + 1][0] = '╗'
          strArr[y * 2 + 1][x + 1][0] = '║'
          strArr[y * 2 + 2][x + 1][0] = '╝'
        }
      }
    })
  })

  return strArr
}

export const calendarMonospace = (timestamp: number): string[] => {
  // Build top of Calendar
  const calendarTop = calendarLines.top.tl + calendarLines.top.t.repeat(20) + calendarLines.top.tr
  const thisMonth = format(timestamp, 'MMMM')
  const space = '░'
  const thisYear = format(timestamp, 'y')
  const spaceMonth = space.repeat(16 - (thisMonth.length + thisYear.length))

  // Create array of Strings
  const newArr = [
    calendarTop,
    calendarLines.top.l +
    ' ' +
    thisMonth +
    ' ' +
    spaceMonth +
    ' ' +
    thisYear +
    ' ' +
    calendarLines.top.r
  ]

  calendarMono(timestamp).forEach((arr) => {
    const anotherArr: string[] = []
    arr.forEach(innerArr => {
      anotherArr.push(innerArr.join(''))
    })

    newArr.push(anotherArr.join(''))
  })

  return newArr
}

const calendarMonoTwo = (timestamp: number): string[][][] => {
  const cl = monoLines

  const strArr: string[][][] = []

  blockcalendar(timestamp).forEach((week, y) => {
    const upperLine: Array<[string, string, string]> = []
    const middleLine: Array<[string, string, string]> = []
    const lowerLine: Array<[string, string, string]> = []

    week.forEach((day, x) => {
      let dayInner = day.day
      if (day.class === 'notmonth') { dayInner = '▒▒' as any }

      let dictpos = cl.mid
      if (x === 0) {
        dictpos = cl.first
      }
      if (x === 6) {
        dictpos = cl.last
      }

      // for each day, check it's x and y coordinates and create walls based on those coordinates
      if (y === 0) {
        upperLine.push([dictpos.ttl, dictpos.tt, dictpos.ttr])
        middleLine.push([dictpos.l, dayInner as any, dictpos.r])
      } else if (y < blockcalendar(timestamp).length - 1) {
        upperLine.push([dictpos.tl, dictpos.t, dictpos.tr])
        middleLine.push([dictpos.l, dayInner as any, dictpos.r])
      } else {
        upperLine.push([dictpos.tl, dictpos.t, dictpos.tr])
        middleLine.push([dictpos.l, dayInner as any, dictpos.r])
        lowerLine.push([dictpos.bl, dictpos.b, dictpos.br])
      }
    })

    if (y < blockcalendar(timestamp).length - 1) {
      strArr.push(upperLine, middleLine)
    } else {
      strArr.push(upperLine, middleLine, lowerLine)
    }
  })

  // Add Today
  blockcalendar(timestamp).forEach((week, y) => {
    week.forEach((day, x) => {
      if (day.class === 'today') {
        strArr[y * 2][x][0] = '╔'
        strArr[y * 2][x][1] = '══'
        strArr[y * 2 + 2][x][1] = '══'
        strArr[y * 2 + 2][x][0] = '╚'
        strArr[y * 2 + 1][x][0] = '║'

        if (x === 6) {
          strArr[y * 2][x][2] = '╗'
          strArr[y * 2 + 1][x][2] = '║'
          strArr[y * 2 + 2][x][2] = '╝'
        } else {
          strArr[y * 2][x + 1][0] = '╗'
          strArr[y * 2 + 1][x + 1][0] = '║'
          strArr[y * 2 + 2][x + 1][0] = '╝'
        }
      }
    })
  })

  return strArr
}

export const calendarMonospaceTwo = (timestamp: number): string[] => {
  // Build top of Calendar
  const cl = monoLines
  const calendarTop = cl.top.tl + cl.top.t.repeat(20) + cl.top.tr
  const thisMonth = format(timestamp, 'MMMM')
  const space = '▒'
  const thisYear = format(timestamp, 'y')
  const spaceMonth = space.repeat(16 - (thisMonth.length + thisYear.length))

  // Create array of Strings
  const newArr = [
    calendarTop,
    cl.top.l +
    ' ' +
    thisMonth +
    ' ' +
    spaceMonth +
    ' ' +
    thisYear +
    ' ' +
    cl.top.r,
    '├──┬──┬──┬──┬──┬──┬──┤',
    '│Su│Mo│Tu│We│Th│Fr│Sa│'
  ]

  calendarMonoTwo(timestamp).forEach((arr) => {
    const anotherArr: string[] = []
    arr.forEach((innerArr) => {
      anotherArr.push(innerArr.join(''))
    })

    newArr.push(anotherArr.join(''))
  })

  return newArr
}

const calendarCirc = (timestamp: number): Array<Array<[string, string, string]>> => {
  const cl = circleLines

  const strArr: Array<Array<[string, string, string]>> = []

  blockcalendar(timestamp).forEach((week, y) => {
    const upperLine: Array<[string, string, string]> = []
    const middleLine: Array<[string, string, string]> = []
    const lowerLine: Array<[string, string, string]> = []

    week.forEach((day, x) => {
      let dayInner = day.day
      if (day.class === 'notmonth') { dayInner = '◯' as any }

      let dictpos = cl.mid
      if (x === 0) {
        dictpos = cl.first
      }
      if (x === 6) {
        dictpos = cl.last
      }

      // for each day, check it's x and y coordinates and create walls based on those coordinates
      if (y === 0) {
        upperLine.push([dictpos.ttl, dictpos.t, dictpos.tr])
        middleLine.push([dictpos.l, dayInner as any, dictpos.r])
      } else if (y < blockcalendar(timestamp).length - 1) {
        upperLine.push([dictpos.tl, dictpos.t, dictpos.tr])
        middleLine.push([dictpos.l, dayInner as any, dictpos.r])
      } else {
        upperLine.push([dictpos.tl, dictpos.t, dictpos.tr])
        middleLine.push([dictpos.l, dayInner as any, dictpos.r])
        lowerLine.push([dictpos.bl, dictpos.b, dictpos.br])
      }
    })

    if (y < blockcalendar.length - 1) {
      strArr.push(middleLine)
    } else {
      strArr.push(middleLine)
    }
  })

  // Add Today
  blockcalendar(timestamp).forEach((week, y) => {
    week.forEach((day, x) => {
      if (day.class === 'today') {
        if (day.class === 'today') {
          if (x < 6) {
            strArr[y][x + 1][1] = '☜'
          }

          if (x > 0) {
            strArr[y][x - 1][1] = '☞'
          }
        }
      }
    })
  })

  return strArr
}

export const calendarCircled = (timestamp: number): string[] => {
  // Build top of Calendar
  const thisMonth = format(timestamp, 'MMMM')

  const monoMonth = thisMonth
    .toUpperCase()
    .split('')
    .map(e => squaredBlackDict[e] || e)
    .join('')

  // Create array of Strings
  const newArr = [monoMonth, '🅂 🄼 🅃 🅆 🅃 🄵 🅂']

  calendarCirc(timestamp).forEach((arr) => {
    const anotherArr: string[] = []
    arr.forEach((innerArr) => {
      anotherArr.push(innerArr.map(e => circledDates[e] || e).join(''))
    })
    newArr.push(anotherArr.join(''))
  })

  const monoArr: string[] = []
  newArr.forEach((string) => {
    monoArr.push(string)
  })

  return monoArr
}

const calendarNormTwo = (timestamp: number): string[][][] => {
  const cl = normalLines

  const strArr: string[][][] = []

  blockcalendar(timestamp).forEach((week, y) => {
    const upperLine: Array<[string, string, string]> = []
    const middleLine: Array<[string, string, string]> = []
    const lowerLine: Array<[string, string, string]> = []

    week.forEach((day, x) => {
      let dayInner = day.day
      if (day.class === 'notmonth') { dayInner = '🅇' as any }

      let dictpos = cl.mid
      if (x === 0) {
        dictpos = cl.first
      }
      if (x === 6) {
        dictpos = cl.last
      }

      // for each day, check it's x and y coordinates and create walls based on those coordinates
      if (y === 0) {
        upperLine.push([dictpos.ttl, dictpos.t, dictpos.tr])
        middleLine.push([dictpos.l, dayInner as any, dictpos.r])
      } else if (y < blockcalendar(timestamp).length - 1) {
        upperLine.push([dictpos.tl, dictpos.t, dictpos.tr])
        middleLine.push([dictpos.l, dayInner as any, dictpos.r])
      } else {
        upperLine.push([dictpos.tl, dictpos.t, dictpos.tr])
        middleLine.push([dictpos.l, dayInner as any, dictpos.r])
        lowerLine.push([dictpos.bl, dictpos.b, dictpos.br])
      }
    })

    if (y < blockcalendar.length - 1) {
      strArr.push(middleLine)
    } else {
      strArr.push(middleLine)
    }
  })

  // Add Today
  blockcalendar(timestamp).forEach((week, y) => {
    week.forEach((day, x) => {
      if (day.class === 'today') {
        strArr[y][x][1] = sansBoldDict[strArr[y][x][1]] || strArr[y][x][1]
      }
    })
  })

  return strArr
}

export const calendarNormalTwo = (timestamp: number): string[] => {
  // Build top of Calendar
  const thisMonth = format(timestamp, 'MMMM')
  const thisYear = format(timestamp, 'y')
  const monthYear = thisMonth + ' – ' + thisYear

  const monoMonth = monthYear
    .toUpperCase()
    .split('')
    .map(e => sansBoldDict[e] || e)
    .join('')

  // Create array of Strings
  const newArr = [monoMonth]

  calendarNormTwo(timestamp).forEach((arr) => {
    const anotherArr: string[] = []
    arr.forEach((innerArr) => {
      anotherArr.push(innerArr.join(''))
    })

    newArr.push(anotherArr.join(''))
  })

  const monoArr: string[] = []
  newArr.forEach((string) => {
    monoArr.push(
      string
        .split('')
        .map(e => sansDict[e] || e)
        .join('')
    )
  })

  return monoArr
}
